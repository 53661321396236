export const EMAIL_STATUS_SENT = 0;
export const EMAIL_STATUS_HARD_BOUNCE = 1;
export const EMAIL_STATUS_DELIVERED = 2;
export const EMAIL_STATUS_OPENED = 3;
export const EMAIL_STATUS_PLACEHOLDER_ERROR = 4;
export const TABLE_SCROLL_ARROW_LENGTH = 300;
export const CATEGORY_TYPE_SYSTEM = 10;
export const CATEGORY_TYPE_CUSTOM = 20;
export const ROLE_TYPE_SYSTEM = 10;
export const ROLE_TYPE_CUSTOM = 20;
export const SCROLL_ARROWS_TOP_POSITION_15 = '15px';
export const SCROLL_ARROWS_TOP_POSITION_56 = '56px';
export const SCROLL_ARROWS_TOP_POSITION_67 = '67px';
export const SCROLL_ARROWS_TOP_POSITION_70 = '70px';
export const SCROLL_ARROWS_TOP_POSITION_77 = '77px';
export const SCROLL_ARROWS_TOP_POSITION_98 = '98px';
export const SCROLL_ARROWS_TOP_POSITION_120 = '120px';
export const SCROLL_ARROWS_RIGHT_POSITION_10 = '10px';
export const SCROLL_ARROWS_RIGHT_POSITION_40 = '40px';
export const SCROLL_ARROWS_RIGHT_POSITION_80 = '80px';
export const REQUEST_RETRY_TIMEOUT = 60000;
export const CLEAR_FILTERS_BLACKLIST_ROUTES = ['Communication'];
export const NESTED_DROPDOWN_ITEM_HEIGHT = 38;
export const MELON_LANGUAGE_EN = 'en';
export const MELON_LANGUAGE_DE = 'de';
export const MELON_LANGUAGE_FR = 'fr';
export const MELON_LANGUAGE_IT = 'it';
export const AXIOS_RESPONSE_TIMEOUTED = 'ECONNABORTED';
export const APPLICATION_FORM = {
  TEMPLATE: {
    CITY_OF_ZURICH: 'lsz',
  },
  FIELD_TYPE: {
    BOOLEAN: 1,
    DATE: 2,
    EMAIL: 3,
    FILE: 4,
    HIDDEN: 5,
    INTEGER: 6,
    PHONE_NUMBER: 7,
    RADIO: 8,
    SELECT: 9,
    SELECT_APARTMENT: 10,
    SELECT_RESIDENTS: 11,
    TEXT: 12,
    TEXTAREA: 13,
    HTML: 14,
    SELECT_COUNTRY: 15,
  },
};
export const ADVERTISEMENT_TYPE_BIDING = 1;
export const ADVERTISEMENT_TYPE_PITCH = 2;
export const HTTP_STATUS_CODE_FORBIDDEN = 403;
export const SELECTED_ITEM_IS_APPLICATION = 0;
export const PHONE_NUMBER_TYPE_LANDLINE = 0;
export const PHONE_NUMBER_TYPE_MOBILE = 1;
export const APARTMENT_SELECTION_SINGLE = 1;
export const APARTMENT_SELECTION_MULTIPLE = 2;
export const FORM_TEMPLATE = {
  CITY_OF_ZURICH: 'lsz',
};

// STZH colors
export const STZH_COLORS = {
  WHITE: '#ffffff',
  MIDNIGHTBLUE: '#0f05a0',
  GREY: '#F4F4F4',
};
// STZH fonts
export const STZH_FONTS = {
  REGULAR: 'HelveticaNeueLTW01_55Roman',
  HEAVY: 'HelveticaNeueLTW05_85Heavy',
};
